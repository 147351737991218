import React from 'react'

class Credit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text
        }
    }

    render(){
        return(
            <div className="credit">
                (Image Credit: {this.state.text})
            </div>
        )
    }
}

export default Credit;