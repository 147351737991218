import React from 'react'
import { Helmet } from 'react-helmet';

import Credit from './Credit'
import GoogleAd from './GoogleAd'
import '../css/article.css'

class Top_5_WoW_Pets extends React.Component {
    render() {
        return (
            <div className="article-container">
                <div className="article">
                    <Helmet>
                        <title>The Top 5 Best Battle Pets in World of Warcraft.</title>
                        <meta name="description" content="The 5 Best Battle Pets for World of Warcraft are Anubisath Idol, Unborn Val’kyr, Mechanical Pandaren Dragonling, Xu-Fu, Cub of Xuen, and Chrominius." />
                    </Helmet>
                    <h1>The Top 5 Best Battle Pets in World of Warcraft.</h1>
                    <img className="titleImage" src="http://pwncastdailyquest.com/wp-content/uploads/2015/09/pet-battle-bonus-event-700x249.jpg" />
                    <description><strong>These 5 pets are essential to any pet battler's arsenal.</strong></description>
                    <h2>1. Anubisath Idol</h2>
                        <img src="https://cdn2.warcraftpets.com/images/pets/big/anubisath-idol.v36c3ae4d9b5ccaaff1c79159f866c0c019b23965.jpg"/>
                        <Credit text="warcraftpets.com" />
                        <h3>Drop:  Emperor Vek'lor </h3>
                        <h3>Type: Humanoid</h3>
                        <h3>Abilities:</h3>
                            <h4>Crush</h4>
                                <p>100% Hit Chance</p>
                                <p>Crush the enemy, dealing 325 Humanoid damage.</p>

                            <h4>Demolish</h4>
                                <p>50% Hit Chance</p>
                                <p>Rams the target, dealing 592 Mechanical damage.</p>
                               
                            <h4>Sandstorm</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 370 Flying damage and turns the weather into a sandstorm for 5 rounds.</p>
                                <p>During a sandstorm, all pets take 74 less damage and their accuracy is reduced by 10%.</p>

                            <h4>Stoneskin</h4>
                                <p>Reduces up to 74 damage from each attack against you.</p>
                                <p>Lasts 5 rounds.</p>

                            <h4>Deflection</h4>
                                <p>Avoids enemy attacks made during the round it is used.</p>
                                <p>This ability always goes first.</p>

                            <h4>Rupture</h4>
                                <p>100% Hit Chance</p>
                                <p>Stone spikes erupt from the ground, dealing 444 Elemental damage and has a 25% chance to stun the target.</p>

                    <h2>2. Unborn Val'kyr</h2>
                        <img src="https://cdn2.warcraftpets.com/images/pets/big/unborn-valkyr.vd2a30c7683231b3bf7ee2e7983ca47524ced918d.jpg"/>
                        <Credit text="warcraftpets.com" />
                        <h3>Location:  Northrend </h3>
                        <h3>Type: Undead</h3>
                        <h3>Abilities:</h3>
                            <h4>Shadow Slash</h4>
                                <p>100% Hit Chance</p>
                                <p>Slashes at the target with dark energy, dealing 310 Undead damage.</p>

                            <h4>Shadow Shock</h4>
                                <p>100% Hit Chance</p>
                                <p>Shocks the target with dark energy, dealing 325 Undead damage.</p>
                               
                            <h4>Siphon Life</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 59 Undead damage per round for 3 rounds and heals the user for 59.</p>

                            <h4>Curse of Doom</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 592 Undead damage after 4 rounds.</p>

                            <h4>Haunt</h4>
                                <p>100% Hit Chance</p>
                                <p>The user transforms into an unholy spirit and enters the target, dealing 148 Undead damage every round.</p>
                                <p>While haunting a target, the user is considered dead.</p>

                            <h4>Unholy Ascension</h4>
                                <p>100% Hit Chance</p>
                                <p>Sacrifices your remaining life to haunt the enemy team, increasing all damage they take by 25% for 9 rounds.</p>

                    <h2>3. Mechanical Pandaren Dragonling</h2>
                        <img src="https://cdn2.warcraftpets.com/images/pets/big/mechanical-pandaren-dragonling.va860208f361c33726c0171cb4be59ca0f8d86c7b.jpg"/>
                        <Credit text="warcraftpets.com" />
                        <h3>Obtained: Pandaren Engineering (50)</h3>
                        <h3>Type: Mechanical</h3>
                        <h3>Abilities:</h3>
                            <h4>Breath</h4>
                                <p>100% Hit Chance</p>
                                <p>Overwhelms the enemy with draconic breath, dealing 310 Dragonkin damage.</p>

                            <h4>Flyby</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 148 Flying damage and increases the target's damage taken by 25% for 3 rounds.</p>
                               
                            <h4>Bombing Run</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 148 Mechanical damage and calls in a bombing run.</p>
                                <p>After 3 rounds the bombs will arrive, dealing 296 Mechanical damage to the current enemy pet.</p>

                            <h4>Thunderbolt</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 488 Elemental damage split evenly across the enemy team.</p>

                            <h4>Explode</h4>
                                <p>100% Hit Chance</p>
                                <p>Instantly kills the caster, dealing damage equal to 40% of the user's total health to the current enemy.</p>
                                <p>Using explode will prevent the caster from activating Failsafe mechanisms.</p>

                            <h4>Decoy</h4>
                                <p>Creates a wooden decoy that protects your team from two attacks.Creates a wooden decoy that protects your team from two attacks.</p>

                    <h2>4. Xu-Fu, Cub of Xuen</h2>
                        <img src="https://cdn2.warcraftpets.com/images/pets/big/xu-fu-cub-of-xuen.va5dffc3aedbe3fadc20496de8a9013fa8dc9ad96.jpg"/>
                        <Credit text="warcraftpets.com" />
                        <h3>Location: Timeless Isle (Master Li)</h3>
                        <h3>Type: Beast</h3>
                        <h3>Abilities:</h3>
                            <h4>Spirit Claws</h4>
                                <p>80% Hit Chance</p>
                                <p>Rakes the enemy with ghostly claws, dealing 494 Beast damage.</p>
                                <p>Always hits when the weather is Moonlight.</p>

                            <h4>Bite</h4>
                                <p>100% Hit Chance</p>
                                <p>Bites at the enemy, dealing 353 Beast damage.</p>                               
                            <h4>Feed</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 353 Beast damage.</p>
                                <p>The user is healed for 100% of the damage dealt.</p>

                            <h4>Moonfire</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 441 Magic damage and turns the weather into Moonlight for 9 rounds.</p>
                                <p>During Moonlight, all pets receive 25% additional healing and Magic abilities deal 10% additional damage.</p>

                            <h4>Vengeance</h4>
                                <p>100% Hit Chance</p>
                                <p>A vengeful attack that deals Beast damage equal to the last hit taken by the user.</p>  

                            <h4>Prowl</h4>
                                <p>Reduces your speed by 30% and increases the damage of your next attack made within 2 rounds by 150%.</p>

                    <h2>5. Chrominius</h2>
                        <img src="https://cdn2.warcraftpets.com/images/pets/big/chrominius.v184d02f103e754140ca29278469bd16ccc6bba4d.jpg"/>
                        <Credit text="warcraftpets.com" />
                        <h3>Drop: Chromaggus</h3>
                        <h3>Type: Dragonkin</h3>
                        <h3>Abilities:</h3>
                            <h4>Bite</h4>
                                <p>100% Hit Chance</p>
                                <p>Bites at the enemy, dealing 296 Beast damage.</p>                               
                            <h4>Arcane Explosion</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 148 Magic damage to the enemy's active pet and 88 Magic damage to the enemy's backline pets.</p>
  
                            <h4>Howl</h4>
                                <p>100% Hit Chance</p>
                                <p>A distant howl frightens the target, increasing the damage they take on the next attack by 100%, lasts 2 rounds.</p>
                                
                            <h4>Ancient Blessing</h4>
                                <p>Heals the user for 414 health and increases the maximum health of your active pet by 5 per level for 9 rounds.</p>

                            <h4>Ravage</h4>
                                <p>100% Hit Chance</p>
                                <p>Deals 296 Beast damage. If the user kills an enemy with Ravage, they restore 296 health.</p>
                                <p>Ravage continues for 2 rounds.</p>

                            <h4>Surge of Power</h4>
                                <p>100% Hit Chance</p>
                                <p>Instantly deals 740 Magic damage. The user must recharge for two rounds afterwards.</p>
                                <p>While recharging, you cannot perform any other actions.</p>
                </div>
                <GoogleAd />
            </div>
        )
    }
}

export default Top_5_WoW_Pets;