import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Shadowlands_Feature from './Components/Shadowlands-Features'
import Top_5_WoW_Pets from './Components/Top_5_WoW_Pets';
import Operation_Crosswind_Guide from './Components/Operation_Crosswind_Guide';
import Obtain_Obsidian_Deathwing from './Components/Obtain-Obsidian-Deathwing';

function App() {
  return (
    <div className="App">
    <Router>
      <div>
        <Switch>
          <Route path="/shadowlands-features">
            <Shadowlands_Feature />
          </Route>
          <Route path="/top-5-wow-pets">
            <Top_5_WoW_Pets />
          </Route>
          <Route path="/operation-crosswind-guide">
            <Operation_Crosswind_Guide />
          </Route>
          <Route path="/obtain-obsidian-worldbreaker">
            <Obtain_Obsidian_Deathwing />
          </Route>
          <Route path="/">
            <Shadowlands_Feature />
          </Route>
        </Switch>
      </div>
    </Router>
    </div>
  );
}

export default App;
