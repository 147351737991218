import React from 'react'
import { Helmet } from 'react-helmet';

import Credit from './Credit'
import GoogleAd from './GoogleAd'
import '../css/article.css'

class Shadowlands_Features extends React.Component {
    render() {
        return (
            <div className="article-container">
                <div className="article">
                    <Helmet>
                        <title>Every new feature in World of Warcraft Shadowlands</title>
                        <meta name="description" content="World of Warcraft’s 8th expansion is bringing in some drastic changes and new features into the nearly two decade old mmorpg." />
                    </Helmet>
                    <h1>Every new feature in World of Warcraft Shadowlands</h1>
                    <img className="titleImage" src="https://s3.dexerto.com/thumbnails/_thumbnailLarge/WoW-Shadowlands-leveling-Dexerto.jpg" />
                    <description><strong>World of Warcraft’s 8th expansion is bringing in some drastic changes and new features into the nearly two decade old mmorpg.</strong></description>

                    <h2>Covenants</h2>
                        <img src="https://massivelyop.com/wp-content/uploads/2019/11/cov2.jpg"/>
                        <Credit text="Blizzard Entertainment" />
                        <p>Covenants are one of the biggest features coming in the Shadowlands expansion. 4 of the 5 core areas feature new faction-like groups that will provide the player with in-game abilities and cosmetics; however the player must select a single covenant upon reaching max level to side with. The new covenants are as follows:</p>
                        <h3>Kyrians of Bastion</h3>
                            <img src="https://wow.zamimg.com/uploads/blog/images/17617-shadowlands-preview-bastion-and-the-kyrian-covenant.jpg"/>
                            <Credit text="Blizzard Entertainment" />
                            <p>The Kyrians are a noble, angelic people that ensure the peace and order of their lands in Bastion are not disturbed. They build constructs to both protect and enforce their laws, as well as being the source of the Spirit Healers on Azeroth</p>
                        <h3>The Necrolords of Maldraxxus</h3>
                            <img src="https://static.icy-veins.com/images/wow/shadowlands/maldraxxus.jpg"/>
                            <Credit text="Blizzard Entertainment" />
                            <p>The Necrolords are made up of races and beings that many would consider evil on Azeroth, such as liches and warlords. They pride themselves on their strength and any who prove to be weak are experimented on relentlessly.</p>
                        <h3>The Night Fae of Ardenweald</h3>
                            <img src="https://static.icy-veins.com/images/wow/shadowlands/ardenweald2.jpg" />
                            <Credit text="Blizzard Entertainment" />
                            <p>The Night Fae fulfill the same role as many of the nature spirits on Azeroth, acting as guardians of nature and ensuring the cycle of life and death goes on uninterrupted.</p>
                        <h3>The Venthyr of Revendreth</h3>
                            <img src="https://gamepedia.cursecdn.com/wowpedia/thumb/2/25/BlizzCon_2019_-_Revendreth_concept.jpg/1200px-BlizzCon_2019_-_Revendreth_concept.jpg"/>
                            <Credit text="Blizzard Entertainment" />
                            <p>The Venthyr act as punishers of the proud and arrogant souls that stand before them, these thralls forced to suffer their punishment, lest they wish to be sent to the Maw instead.</p>
                    <h2>Level Squish</h2>
                        <p>This expansion is also introducing a new means of leveling. The new max level is now 60. Yes, that’s right 60. All previous zone will be scaled according to your character’s level between 1-50. When it comes time for your character to journey through the Shadowlands content you will progress through levels 50-60.</p>
                    <h2>Torghast, Tower of the Damned</h2>
                        <img src="https://static.icy-veins.com/images/wow/shadowlands/torghast-concept-small.jpg" />
                        <Credit text="Blizzard Entertainment" />
                        <p>Torghast is this expansion’s second biggest feature. It is a form of “endless” dungeon that you can explore alone or with up to four friends. This dungeon was inspired by the roguelike genre. As such, each and every time you go through this dungeon, it will be different. As you progress through to dungeon you will receive power ups and abilities associated with the class you are currently playing. The rewards associated with this dungeon have yet to be seen but they are sure to be great.</p>
                    <h2>End Game Rewards</h2>
                        <p>Blizzard is working towards removing most of the randomness that previously existed when it came to getting end-game gear. One example of this has to do with the weekly chest reward from completing a mythic+ dungeon the previous week. Instead of receiving one item, you will now get to pick between 5 or 6 items and there will always be a fallback reward should none of those items pique your interest.
                            Additionally, Blizzard has been modifying the way the crafting system will work when it comes to crafting mythic level gear. Instead of crafting gear and wasting materials to get a piece of gear with two random stats that you did not want, you can now add gems with the stats you would want on the crafted piece to guarantee the new piece of gear will now have those stats. For example if you put a strength gem and haste gem into the crafting process you would get a piece of gear that has strength and haste on it.
                        </p>
                    <h2>Death Knights</h2>
                        <img src="https://cdn.mos.cms.futurecdn.net/jvPLdv2ApKVqFz7X8Dqgnf-650-80.jpg" />
                        <Credit text="Blizzard Entertainment" />
                        <p>With Sylvanas taking down Bolvar, he needs forces now more than ever. As such there will no longer be any restrictions on what races are allowed to be death knights, and yes this includes allied races. </p>
                    <h2>Character Customization</h2>
                        <img src="https://cdn.mos.cms.futurecdn.net/zEMNXsMcZtcuthDHHseiTf-650-80.jpg" />
                        <Credit text="Blizzard Entertainment" />
                        <p>If you’ve ever been frustrated with the lack of options available to customize your character exactly how you want them, then you’ll be pleased to hear that Blizzard is making attempts to improve upon how customizable each race is. For example, Blizzard showcased that there would be many more Human options to make your human characters look much more like the real you.</p>
                    <h2>Release Date</h2>
                        <p>Currently there is no release date set, however following previous expansion timelines it wouldn’t be unreasonable to expect the new expansion to be released sometime in the fall or early winter of 2020.</p>
                </div>
                <GoogleAd />
            </div>
        )
    }
}

export default Shadowlands_Features;