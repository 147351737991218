import React from 'react'
import { Helmet } from 'react-helmet';

import Credit from './Credit'
import GoogleAd from './GoogleAd'
import '../css/article.css'

class Operation_Crosswind_Guide extends React.Component {
    render() {
        return (
            <div className="article-container">
                <div className="article">
                    <Helmet>
                        <title>The Easiest Way to Complete Operation Crosswind in Call of Duty Modern Warfare</title>
                        <meta name="description" content="If you are having difficulty clearing Operation Crosswind, don’t worry as you are not the only one. It took my friends and I multiple attempts before we were able to clear it, but this is how we managed to do it:" />
                    </Helmet>
                    <h1>The Easiest Way to Complete Operation Crosswind in Call of Duty Modern Warfare</h1>
                    <img className="titleImage" src="https://specials-images.forbesimg.com/imageserve/5dbf26a6ca425400079c58b0/960x0.jpg?fit=scale" />
                    <description>Operation Crosswind is very difficult. But with this guide you can complete it just as my friends and I did</description>
                    <h2>Disrupting the Communication Systems</h2>
                        <p>You will begin the mission within a house out in the middle of a field. Make sure to equip your guns with silencers and loot the house of all available field upgrades (They will be in small blue boxes). You will want to remain as stealthy as possible throughout the first two phases of the mission as it will make things much easier.</p>
                        <p>Once you are kitted up, head out of the house and make your way towards the compound. Before getting too close, take out the three snipers that are located on the rooftops as they will be the first to spot you. After the snipers have been taken down, you can make your way towards the roadside, in which there are typically two to three enemies walking along the road. Again, take them out silently and make your way into the compound. We found that entering the compound from the left was the easiest as there were very few to no enemies between the ground floor and the roof. </p>
                        <p>Once you reach the roof, carefully take out the three enemies on the parking deck and the two enemies that pace along the far left and right bridges connecting to the larger compound. At this point in time we found that splitting the group into two parties of two worked best. Have one group focus on uploading the bug to the first communications system while the other group makes their way inside the building towards the second communications system. (Note: If you are unaware of where the second communications system is located, attempt the mission a few times until you are comfortable with the flow of the mission and the area). Doing this will significantly speed up your attempts on this mission and also make it considerably easier to remain stealthy.</p>
                        <p>After both communications systems have been dealt with you will be presented with two options. If you managed to disrupt both systems without alarming the enemy, you will be tasked with getting enemy disguises in the basement of the compound. When my group did this, the enemies still discovered us despite our disguises, so I would not recommend this path. The other option is to go loud and run directly out of the compound towards the plane that you now have to board.</p>
                    <h2>Boarding the Plane</h2>
                        <p>You will find yourself outside of the compound now on an airport runway. This is one of the easiest parts of the mission.</p>
                        <p>There will be a four-seater pickup truck for your group or four ATVs. My group opted to take the ATVs however both are viable. The trick to this section is to wait by the vehicles until the plane has landed and completely turned around. If you drive out towards the plane before it is ready for cargo, enemies will begin to swarm you. If you simply wait like this you can drive right up behind the plane and board instantly instead of having to wait and defend.</p>
                    <h2>Retrieve the Nuclear Power Core</h2>
                        <p>You will find yourself on a very turbulent plane making its way to who knows where. You will need to retrieve the nuclear power core within a few minutes or you will fail the mission. However do not worry as that is plenty of time to complete this section.</p>
                        <p>Begin by making your way up the plane until you come to a point to where you need to place a breaching device on a door. Make sure your group is prepared because beyond this door is a juggernaut waiting for you. Hit him with a few molotovs and he should go down no problem.</p>
                        <p>Now make your way into the lower level of the plane. Again, clear out the enemies here but do NOT grab the nuclear core yet. Before you grab the nuclear core, clear out the enemies all the way to the back of the plane. Now make sure your team is completely geared up. Drop ammo boxes and body armor and prepare for the most difficult section of the mission. </p>
                        <p>Once you grab the power core, you will have 18 or so seconds to make your way out of the plane. Luckily you have cleared out all of the enemies so you can run straight to the back and breach through the plane hull.</p>
                    <h2>Exfiltrate with the Power Core</h2>
                        <p>This is easily the most difficult part of the mission, however there is an easy trick that you can use to ensure you win. Once you jump out of the plane you will parachute down into an area with a few warehouses and a broken plane. Have one of the people who is not carrying the power core make their way to the back left warehouse. Once iside the warehouse, have them make their way up to the top of the building. Near the top there should be a ladder leading up to the final floor which is a long grated walkway. The good news is that the enemies aren’t able to shoot through this grated walkway. For the remainder of this mission this person needs to stay up here and just make sure they do not die, it is very easy to defend the ladder should any enemies attempt to climb it.</p>
                        <p>Once you have someone in place at the top of the warehouse, the rest of the group can begin to make their way towards the exfiltration site with the nuclear power core. Do not worry if you go down because you will respawn a minute after dying since one of your teammates is alive (The guy in the warehouse). Once you respawn you will parachute back in and can slowly work the power core closer and closer to the extraction point. The enemies will not touch the power core so it will be exactly where it was last dropped. Simply rinse and repeat this process and many times as it takes until you have placed the power core on the helicopter. </p>
                        <p>Once the power core is on the helicopter and at least one person has boarded the helicopter, the team member in the warehouse can come down. Once you are in the helicopter you are invulnerable so you have already successfully completed the mission. If the team member from the warehouse is not able to make it to the helicopter it doesn’t matter, as the helicopter will take off upon their death, ultimately giving everyone credit for completing the mission.</p>
                    <h2>Congratulations</h2>
                        <p>There you have it, you have now completed one of the four base co-op missions for Call of Duty Modern Warfare. I hope this quick summary guide was helpful to many of you and I wish you the best of luck in completing this mission.</p>
                </div>
                <GoogleAd />
                </div>
        )
    }
}

export default Operation_Crosswind_Guide