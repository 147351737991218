import React from 'react'
import { Helmet } from 'react-helmet';

import Credit from './Credit'
import GoogleAd from './GoogleAd'
import '../css/article.css'

class Obtain_Obsidian_Deathwing extends React.Component {
    render() {
        return(
            <div className="article-container">
                <div className="article">
                    <Helmet>
                        <title>How to Obtain the Obsidian Worldbreaker During World of Warcraft’s 15th Anniversary</title>
                        <meta name="description" content="It has finally arrived, the mount that so many have been waiting for; the Deathwing mount. Here we break down the steps so that you can get your very own Obsidian Worldbreaker." />
                    </Helmet>
                    <h1>How to Obtain the Obsidian Worldbreaker During World of Warcraft’s 15th Anniversary</h1>
                    <img className="titleImage" src="https://i.ytimg.com/vi/y8lfFBsNgDU/maxresdefault.jpg" />
                    <description><strong>It has finally arrived, the mount that so many have been waiting for; the Deathwing mount.</strong></description>

                    <h2>Minimum Requirements</h2>
                        <p>In order to obtain this mount, you will need a character at level cap (120). In addition to this you will also need to have an item level at 380 or above. If you aren’t quite at that item level, simply running a few mythic level dungeons will get you there in no time, or even grabbing gear from Raid Finder.</p>
                
                    <h2>Starting the Quest for the Mount</h2>
                        <p>In order to start this quest, you will need to login between November 5th, 2019 to January 7th, 2020. Once you login, you should see that there is mail waiting for you in the mailbox. As per usual this letter is from the World of Warcraft Dev Team congratulating you on another year in Azeroth. Inside the letter will be a small package.</p>
                        <p>Once you retrieve the package from the mail and open it, you will receive a few items; one of which is a quest item pointing you towards the Caverns of Time. Before we head off there though, take a moment to check out the new pet you also just received, Lil’ Nefarian!</p>
                        <img src="https://cdn2.warcraftpets.com/images/pets/big/lil-nefarian.vf0271d639ca1b36ad3259ac0fe81e070a9249f3f.jpg"/>
                        <Credit text="warcraftpets.com" />
                        <p>Head on over to the Caverns of Time however you prefer and make your way down to the central chamber in which you will find Chromie. She will take the letter you received in the mail and offer you two quests. </p>
                        <p>For the time being you will only need to grab the quest detailing you to relive memories from the Burning Crusade, Wrath of the Lich King, and Cataclysm expansions. However I would encourage everyone to also check out the Classic Alterac Valley game mode that is available during the anniversary.</p>
                
                    <h2>Completing the Raid Scenarios</h2>
                        <p>Once you have picked up the quest to relive memories from past expansions, you can speak with Chromie in order to queue up for three different scenarios.</p>
                        <p>Each scenario takes place at a specific point in World of Warcraft’s history and will have you challenging three raid bosses from that point in history. Don’t worry, as you typically only have to wither down about 25% of the boss’ health before you are teleported into the next encounter.</p>
                        <p>You will have to defeat these three bosses in order and without your group wiping. If your group wipes at any time throughout any of the three fights you will have to start from the very first encounter. The bosses are as follows: Burning Crusade: Lady Vashj, Kael’thas Sunstrider, and Archimonde; Wrath of the Lich King: Heigan the Unclean, Anub’arak, The Lich King; Cataclysm: Cho’gall, Nefarian, and Ragnaros. In general though, it should not take more than an hour to do one of any of these three sets of encounters.</p>
                        <p>An added bonus to these timewalking raid encounters is that they also drop item level 430 gear at the end, which is a great boost; especially if you are bringing your character in at just a 380 item level. </p>

                    <h2>Claiming the Obsidian Worldbreaker</h2>
                        <p>Once you have completed the scenario events for the Burning Crusade, Wrath of the Lich King, and Cataclysm expansions, you will receive an achievement called: Memories of Fel, Frost, and Fire. Which will then reward you with your new mount, the Obsidian Worldbreaker.</p>
                        <p>Congratulations you have successfully earned your Mini-Deathwing! </p>
                </div>
                <GoogleAd />
            </div>
        )
    }
}

export default Obtain_Obsidian_Deathwing