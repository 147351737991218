import React from 'react'

class GoogleAd extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            num: props.num
        }
    }

    componentDidMount () {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render(){
        return(
            <div className="ad-container">
                <ins className="adsbygoogle"
                data-ad-client="ca-pub-7815298632413205"
                data-ad-slot="6671386808"
                ></ins>
            </div>
        )
    }
}

export default GoogleAd;